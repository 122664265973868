import React, { FC, useEffect, useState } from "react";
// @ts-ignore
import Logo from "../../assets/header/ClubCasinoLogo.png";
// @ts-ignore
import Bonus from "../../assets/RegisterFB/zabirayBonus.png";
// @ts-ignore
import bezVidigrashu from "../../assets/RegisterFB/bezVidigrashu.png";
// @ts-ignore
import cube from "../../assets/RegisterFB/cube3.png";
import Register from "../../Components/Auth/Register";
import CrossPopupIcon from "../../Components/icons/CrossPopupIcon";
import { Link } from "react-router-dom";

interface IRegistrationFromFacebook {
  register: any;
}

const RegistrationFromFacebook: FC<IRegistrationFromFacebook> = ({
  register,
}) => {
  const [popup, setPopup] = useState<boolean>();
  useEffect(() => {
    setPopup(false);
  }, []);
  return (
    <>
      <main className="register__fb">
        <div className="register__fb__container">
          <div className="fb__logo__container">
            <div className="fb__logo__title">
              <img className="fb__logo" src={Logo} alt="logotype ClubCasino" />
              <div className="fb__title__container">
                <h2 className="fb__title fb__title__yellow">CLUB</h2>
                <h2 className="fb__title">CASINO</h2>
              </div>
            </div>
            <div className="register__fb__login__container">
              <Link to="/login" className="register__fb__login">
                Вхід
              </Link>
            </div>
          </div>
          <div className="fb__get__container">
            <img className="fb__img__bonus" src={Bonus} alt="" />
          </div>
          <div className="fb__img__bezVidigrashu_container">
            <img
              className="fb__img__bezVidigrashu"
              src={bezVidigrashu}
              alt=""
            />
          </div>
          <div className="register__get__btn__container">
            <button
              onClick={() => setPopup(true)}
              className="register__get__btn"
            >
              uzyskać
            </button>
          </div>
          <div className="fb__img__cube__container">
            <img className="fb__img__cube" src={cube} alt="cube" />
          </div>
        </div>
      </main>
      <div
        className={
          popup
            ? "popup__register__fb__container active"
            : "popup__register__fb__container "
        }
        onClick={() => setPopup(false)}
      >
        <div
          className={
            popup ? `popup__register__fb active` : " popup__register__fb  "
          }
          onClick={(e) => e.stopPropagation()}
        >
          <div onClick={() => setPopup(false)} className="cross__popup">
            <CrossPopupIcon />
          </div>
          <Register popup={popup} handleRegister={register} page={"fb"} />
        </div>
      </div>
    </>
  );
};

export default RegistrationFromFacebook;
