import React, {
  FC,
  Fragment,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import CardOffer from "../Components/CardOffer";
import { Link } from "react-router-dom";
import CardTelegram from "../Components/CardTelegram";
import useAuth from "../hooks/useAuth";

interface IPromokod {
  state: any;
  updateOffers: any;
}

const Promokod: FC<IPromokod> = ({ state, updateOffers }) => {
  const [noneOffers, setNoneOffers] = useState(false);
  const { user } = useAuth();
  const [sub1, setSub1] = useState(
    user?.sub1 ?? window.localStorage.getItem("sub1") ?? "clubcasino"
  );
  useEffect(() => {
    setSub1(user?.sub1 ?? window.localStorage.getItem("sub1") ?? "clubcasino");
  }, [user]);
  useLayoutEffect(() => {
    if (!state[0]) {
      setNoneOffers(true);
    }
  }, []);
  const componentNonePromo = () => {
    return (
      <div className="offer__none__container">
        <p className="offer__none__title">O cholera!</p>
        <p className="offer__none__text">
          Niestety, obecnie nie ma czegoś takiego{" "}
        </p>
        <p className="offer__none__text">dostępnych kodów promocyjnych</p>
        <Link className="offer__none__link" to="/">
          Do strony głównej
        </Link>
      </div>
    );
  };
  return (
    <>
      <div className="title__bg">
        <p className="title__rozdil">kody promocyjne</p>
      </div>
      {noneOffers && componentNonePromo}
      <div className="card__container">
        {state?.map((item: any, index: number) => (
          <Fragment key={index + item.title}>
            <CardOffer item={item} updateOffers={updateOffers} />
          </Fragment>
        ))}
        {sub1 === "brat" && <CardTelegram />}
        {sub1 === "alex" && <CardTelegram />}
        {sub1 === "tts" && <CardTelegram />}
        {sub1 === "tti" && <CardTelegram />}
        {sub1 === "mik" && <CardTelegram />}
        {sub1 === "moroz" && <CardTelegram />}
        {sub1 === "klon" && <CardTelegram />}
      </div>
    </>
  );
};

export default Promokod;
