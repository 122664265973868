import React from "react";
// @ts-ignore
import email from "../../assets/thank/email.png";

const ThankPageFb = () => {
  return (
    <div className="thankPage">
      <div className="thankPage__container">
        <p className="thankPage__title">
          List został wysłany na Twój adres e-mail
        </p>
        <img className="thankPage__img" src={email} alt="koperta z listem " />
        <p className="thankPage__text">
          Wysłaliśmy wiadomość, aby dokończyć rejestrację.
        </p>
        <p className="thankPage__text">
          Jeśli w ciągu 2 minut nie otrzymasz wiadomości aktywacyjnej:
        </p>
        <ul className="thankPage__list__container">
          <li className="thankPage__list">
            Sprawdź foldery za pomocą{" "}
            <span className="thankPage__list__span">spam</span> lub{" "}
            <span className="thankPage__list__span">reklama</span> .
          </li>
          <li className="thankPage__list">
            Sprawdź, czy poprawnie wprowadziłeś swój adres.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ThankPageFb;
