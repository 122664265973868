import React, { useMemo, useState } from "react";

const RestorePassword = ({
  onRestore,
}: {
  onRestore: (newPassword: string) => void;
}) => {
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");

  //TODO: check validation, etc
  const isValid = useMemo(
    () => password.length > 6 && password === passwordRepeat,
    [password, passwordRepeat]
  );

  return (
    <div className="reset__password">
      <p className="reset__title">odzyskiwanie hasła</p>
      <div className="reset__inp__container">
        <label className="reset__label__container" htmlFor="">
          Nowe hasło
        </label>
        <span className="reset__input__lock__icon" />
        <input
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="reset__inp__password"
          placeholder="Wprowadź nowe hasło"
          type="password"
        />
      </div>
      <div className="reset__inp__container">
        <label className="reset__label__container" htmlFor="">
          Potwierdzenie nowego hasła
        </label>
        <span className="reset__input__lock__icon" />
        <input
          value={passwordRepeat}
          onChange={(e) => setPasswordRepeat(e.target.value)}
          className="reset__inp__password"
          placeholder="Powtórz nowe hasło"
          type="password"
        />
      </div>
      <div className="reset__btn__container">
        <button
          disabled={!isValid}
          className="reset__btn"
          onClick={() => {
            onRestore(password);
          }}
        >
          Zmiana hasła
        </button>
      </div>
    </div>
  );
};

export default RestorePassword;
