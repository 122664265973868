import React, { FC, useEffect, useState } from "react";
import CardOffer from "../Components/CardOffer";
import IOffers from "../interface/IOffers";
import CardTelegram from "../Components/CardTelegram";
import useAuth from "../hooks/useAuth";

const General: FC<IOffers> = ({ state, updateOffers }) => {
  const { user } = useAuth();
  const [sub1, setSub1] = useState(
    user?.sub1 ?? window.localStorage.getItem("sub1") ?? "clubcasino"
  );
  useEffect(() => {
    setSub1(user?.sub1 ?? window.localStorage.getItem("sub1") ?? "clubcasino");
  }, [user]);
  return (
    <>
      <div className="title__bg">
        <p className="title__rozdil">RANKING KASYN</p>
      </div>
      <div className="card__container">
        {state?.map((item: any, index: any) => (
          <CardOffer
            key={index + item.title + item.id}
            item={item}
            updateOffers={updateOffers}
          />
        ))}
        {sub1 === "brat" && <CardTelegram />}
        {sub1 === "alex" && <CardTelegram />}
        {sub1 === "tts" && <CardTelegram />}
        {sub1 === "tti" && <CardTelegram />}
        {sub1 === "mik" && <CardTelegram />}
        {sub1 === "moroz" && <CardTelegram />}
        {sub1 === "klon" && <CardTelegram />}
      </div>
    </>
  );
};

export default General;
