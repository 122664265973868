import React, { useEffect, useState } from "react";
// @ts-ignore
import email from "../assets/thank/email.png";
import ReactHelmet from "../Components/ReactHelmet";

const ThankRecreative = () => {
  const [number, setNumber] = useState(3);
  useEffect(() => {
    const interval = setInterval(() => {
      if (number <= 3 && number !== 0) {
        // @ts-ignore
        setNumber((prevState) => {
          if (prevState <= 3) {
            return prevState - 1;
          }
        });
      }
      if (number === 0) {
        window.location.href = "/";
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [number]);
  return (
    <div className="thankPage">
      <ReactHelmet />
      <div className="thankPage__container">
        <p className="thankPage__title">Dziękujemy za rejestrację</p>
        <img className="thankPage__img" src={email} alt="koperta z listem " />
        <p className="thankPage__text">
          Zostaniesz przekierowany na stronę główną przez {number}
        </p>
      </div>
    </div>
  );
};

export default ThankRecreative;
