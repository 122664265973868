import React, { FC, useState } from "react";
import NotifyError from "../Components/Notify/NotifyError";

const ResetPassword: FC<{
  onReset: (email: string) => void;
}> = ({ onReset }) => {
  const [email, setEmail] = useState<string>("");
  const re =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  const sendMail = () => {
    if (!re.test(String(email).toLowerCase())) {
      NotifyError("Nieprawidłowy adres e-mail");
    } else {
      onReset(email);
    }
  };

  return (
    <div className="reset__mail">
      <p className="reset__mail__subtitle">Przywracanie dostępu</p>
      <p className="reset__mail__text">Wyślemy Ci wiadomość e-mail </p>
      <p className="reset__mail__text">
        z instrukcjami dotyczącymi zmiany hasła
      </p>
      <div className="reset__mail__input__container">
        <label className="reset__mail__label" htmlFor="">
          {" "}
          Poczta
        </label>
        <span className="reset__input__email__icon" />
        <input
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          value={email}
          placeholder="wprowadź swój adres e-mail"
          className="reset__mail__input"
        />
      </div>
      <div className="reset__mail__btn__container">
        <button className="reset__mail__btn" onClick={() => sendMail()}>
          Kontynuuj
        </button>
      </div>
    </div>
  );
};

export default ResetPassword;
