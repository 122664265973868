import React from "react";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__sub__container">
        <h3 className="footer__sub__title__number">18+</h3>
        <p className="footer__text__copyright">COPYRIGHT © 2024 CLUB CASINO</p>
      </div>
      <div className="footer__sub__container footer__sub__vidmova">
        <h3 className="footer__sub__title">WYŁĄCZENIE ODPOWIEDZIALNOŚCI</h3>
        <p className="footer__text">
          Użytkownik musi upewnić się, że spełnia wszystkie wymogi wiekowe i
          inne i inne wymogi regulacyjne przed wejściem do kasyna lub
          postawieniem zakładu. Informacje na tej stronie są przeznaczone
          wyłącznie dla graczy kasynowych.
        </p>
      </div>
      <div className="footer__sub__container footer__sub__gra ">
        <h3 className="footer__sub__title">ODPOWIEDZIALNA GRA</h3>
        <p className="footer__text">
          Witryna Club Casino nie prowadzi gier na prawdziwe pieniądze i nie
          jest odpowiedzialna za jakiekolwiek straty poniesione podczas gry w
          kasynie. Zawsze zachęcamy do korzystania z odpowiedniej gry losowej.
        </p>
      </div>
      {/* <div className="footer__sub__container footer__unsubscribe__link__container">
        <NavLink exact to="/unsubscribe" className="footer__unsubscribe__link">
          Rezygnacja z subskrypcji biuletynów
        </NavLink>
      </div> */}
    </footer>
  );
};

export default Footer;
